import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Button, Popover } from 'antd'
import '../styles/UserOutput.css'
import { styleButtonActive } from './Home'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { InsurancePlan, getAllPlan } from '../data/plan'
import { getCostByGenderAndAge } from '../data/cost'
import LogoLandscape from '../assets/icons/logo_deva_landscape.png'

export interface UserOutputProps {
  gender: string
  age: number
  selectedPlan: InsurancePlan|null
  onPlanSelected: any
  setSpinning: any
}

export default function UserOutput({
  gender,
  age,
  selectedPlan,
  onPlanSelected,
  setSpinning,
}: UserOutputProps) {
  const [insuranceCost, setInsuranceCost] = useState<any>()
  const [insurancePlan, setInsurancePlan] = useState<InsurancePlan[]>([])

  useEffect(() => {
    async function getCostData() {
      const costData: any = await getCostByGenderAndAge(age, gender)
      setInsuranceCost(costData)
      setSpinning(false)
    }
    if (insuranceCost === undefined) getCostData()
  }, [insuranceCost, age, gender, setSpinning])

  useEffect(() => {
    async function getInsurancePlanData() {
      const allPlan = await getAllPlan()
      setInsurancePlan(allPlan)
      onPlanSelected(allPlan[0])
    }
    if (insurancePlan.length === 0) getInsurancePlanData()
  }, [insurancePlan])

  return (
    <Fragment>
      <Row justify="center" gutter={[0, 10]} id="planList">
        {insurancePlan.map((plan: InsurancePlan) => {
          return (
            <Col
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="center"
              key={plan.planId}
            >
              <Button
                className="buttonPlan center"
                key={plan.planId}
                onClick={() => {
                  onPlanSelected(plan)
                }}
                style={selectedPlan && selectedPlan.planId === plan.planId ? styleButtonActive : {}}
              >
                <Popover
                  content={plan.description}
                  title={plan.name}
                  placement="top"
                  trigger="click"
                >
                  <QuestionCircleOutlined className="iconDescription" />
                </Popover>
                <p className="planTitle">{plan.title}</p>
                <p className="planBedDescription">{`${plan.bed} Kasur / Kamar`}</p>
                <p className="planSubtitle">{plan.subtitle}</p>
                <p className="planWatermark">
                  <img src={LogoLandscape} alt={'Deva Kumala Asuransi'} />
                </p>
                <p className="planPriceUnit">{plan.priceUnit}</p>
                <p className="planCost">
                  {insuranceCost[plan.planId].toLocaleString()}
                </p>
                <p className="planPeriodUnit">
                  setara{' '}
                  {Math.ceil(insuranceCost[plan.planId] / 12).toLocaleString()}
                  /bulan
                </p>
                <Row justify={'center'} className="planLimit">
                  <Col>
                    <p className="planLimitTitle">
                      Limit per tahun
                      <span className="planLimitBoosterDesc"> +Booster</span>
                    </p>
                    <span className="planLimitCoverage">
                      {plan.limitCoverage}M
                    </span>
                    <span className="planLimitBooster">
                      +{plan.limitBooster}M
                    </span>
                  </Col>
                </Row>
              </Button>
            </Col>
          )
        })}
      </Row>
    </Fragment>
  )
}
